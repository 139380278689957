<template>
  <div class="luckyStarRecord">
    <Star-Top></Star-Top>
    <div class="container">
      <!-- 未完成的回合/已完成的回合(tab切换) begin  -->
      <div class="tab-title">
        <div class="tit" :class="[isRoud==index?'isSelectd':'']" v-for="(item,index) in roundsTabList" :key="index" @click="chooseRound(index)">
          <span>{{$t("menu."+item)}}</span><i></i>
        </div>
      </div>
      <!-- 未完成的回合/已完成的回合(tab切换) end  -->
      <Unfinished-Rounds v-if="isRoud==0"></Unfinished-Rounds>
      <Completed-Rounds v-if="isRoud==1"></Completed-Rounds>
    </div> 
  </div>
</template>

<script>
import StarTop from '../common/StarTop.vue';
import '../styles/LuckyStarRecord.css'
import UnfinishedRounds from '../components/StarRecordCom/UnfinishedRounds.vue'
import  CompletedRounds from '../components/StarRecordCom/CompletedRounds.vue'
export default {
  name: 'LuckyStarRecord',
  components:{StarTop,UnfinishedRounds,CompletedRounds},
  data() {
    return {
      isRoud:0,//0默认未完成的回合，1是已完成的回合
      roundsTabList:["unfinishedRoundsName","completedRoundsName"],
    }
  },
  methods:{
    chooseRound(index){
      this.isRoud = index;
    }
  }
}
</script>

<style>

</style>